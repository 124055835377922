<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { debounce } from "@/helpers/debounce";

export default {
    page: {
        title: "Daftar Jadwal Sertifikasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    prop: {
        Skema: {
            type: Object,
            default: () => {
                return {
                    id: 1,
                    nama: "Skema Sertifikasi 1",
                };
            },
        },
    },
    components: {
        Layout,
        PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Kode Jadwal", name: "-" },
            { width: "auto", label: "Nomor Surat Permohonan", name: "-" },
            { width: "auto", label: "Tanggal Surat Permohonan", name: "-" },
            { width: "auto", label: "Nama PIC", name: "-" },
            { width: "auto", label: "Skema", name: "-" },
            { width: "auto", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "200px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Daftar Jadwal Sertifikasi",
            items: [
                {
                    text: "Asesi",
                    href: "/pendaftaran-asesi",
                },
                {
                    text: "Daftar Jadwal Sertifikasi",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: false,
            table_data: [],
            columns: columns,
            sortKey: "id", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
                skema_sertifikasi_id: null,
                role_id: localStorage.session_role_active_id,
                // asesor_id: null,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },

            optionsSkemaSertifikasi: [],
            // optionsAsesi: [],
            skema_sertifikasi_selected: null,
            // asesi_selected: null
        };
    },
    created() {
        this.searchDatatable = debounce(() => {
            this.getDataTable();
            // call fetch API to get results
        }, 500);
    },
    mounted() {
        // this.table_data = this.dataJadwal;
        this.getDataTable();
        this.getSkemaSertifikasi();
        // this.getMasterAsesi();
    },
    methods: {
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getMasterAsesi(){
        //     // GET MASTER KARYAWAN
        //     let self = this;

        //     // load data master karyawan
        //     var config = {
        //         method: "get",
        //         url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-user",
        //         headers: {
        //             Accept: "application/json",
        //             Authorization: "Bearer " + localStorage.access_token,
        //         },
        //     };
        //     axios(config)
        //         .then(function (response) {
        //             var response_data = response.data;
        //             var response_data_fix = response_data.data;
        //             if (response_data.meta.code == 200) {
        //                 self.optionsAsesi = response.data.data.referensi;
        //             } else {
        //                 Swal.fire({
        //                 icon: "error",
        //                 title: "Oops...",
        //                 text: response_data_fix.data.message,
        //                 });
        //             }
        //     })
        //     .catch(function (error) {
        //     console.log(error);
        //     });
        // },
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        toPage: function (url) {
            let self = this;
            self.getDataTable(url);
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/asesi") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        if (self.tableData.draw == response_data_fix.draw) {
                            self.table_data = response_data_fix.list_data.data;
                            self.configPagination(response_data_fix.list_data);
                            self.currentTablePage = response_data_fix.list_data.current_page;
                            self.loadingTable = false;
                        }
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        configPagination(data) {
            console.log(data);
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        ubahStatus(id) {
            console.log(id);
            var alert_text = "Apakah Anda yakin untuk mengubah status?";
            Swal.fire({
                title: "Peringatan !",
                text: alert_text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                } else {
                    Swal.close();
                }
            });
        },
        filterDatatable() {
            let self = this;
            self.tableData.skema_sertifikasi_id = self.skema_sertifikasi_selected?.id_skema_sertifikasi;
            // self.tableData.asesor_id = self.asesi_selected?.user_id;
            self.getDataTable();
        },
        changeStatusData(status, id) {
            let self = this;

            let text_alert = "";
            if (status == true) {
                text_alert = "Apakah Anda yakin untuk menghapus data tersebut?";
            } else {
                text_alert = "Apakah Anda yakin untuk menghapus data tersebut?";
            }

            Swal.fire({
                title: "Perhatian",
                text: text_alert,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "put",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/" + id + "/status",
                        data: {
                            id: id,
                            status: status,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        console.log(response);
                        if (response_data.status != true) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Data berhasil dihapus.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.getDataTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Daftar Jadwal Sertifikasi</div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="statusTable">Pilih Skema Sertifikasi</label>
                                        <v-select :options="optionsSkemaSertifikasi" label="nama_skema_sertifikasi" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                                    </div>
                                    <!-- <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih Asesi</label>
                                        <v-select
                                            :options="optionsAsesi"
                                            label="name"
                                            v-model="asesi_selected"
                                            placeholder="Pilih Nama Asesi"
                                        ></v-select>
                                    </div> -->
                                    <div class="form-group col-md-2 mt-4">
                                        <button class="btn btn-info btn-sm" style="margin-top: 10px !important" v-on:click="filterDatatable"><i class="fas fa-filter"></i> Filter</button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="searchDatatable()" />
                                    </div>
                                </div>
                                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                    <tbody>
                                        <tr v-if="loadingTable">
                                            <td colspan="11" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                        </tr>
                                        <tr v-else-if="table_data.length == 0">
                                            <td class="text-center" colspan="11">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ ((parseInt(tableData.length) * parseInt(currentTablePage)) - parseInt(tableData.length)) + key_data + 1}}.
                                            </td>
                                            <td>{{ row_data.pendaftaran?.kode_jadwal }}</td>
                                            <td>{{ row_data.pendaftaran?.pendaftaran_nomor }}</td>
                                            <td>{{ row_data.pendaftaran?.pendaftaran_tanggal }}</td>
                                            <td>{{ row_data.pendaftaran?.pic_nama }}</td>
                                            <td>{{ row_data.pendaftaran?.skema_nama }}</td>
                                            <td>
                                                <span class="badge bg-warning" v-if="row_data.pendaftaran?.status_id == '14ee35ae-3c10-11ee-a5ac-0242ac110009'"> {{ row_data.status_nama }} </span>
                                                <span class="badge bg-info" v-else> {{ row_data.pendaftaran?.status_nama }} </span>
                                            </td>
                                            <td class="text-center">
                                                <!-- JIKA DRAFT -->
                                                <div class="d-grid gap-1">
                                                    <router-link :to="{ name: 'detail-pendaftaran_asesi', params: { id: row_data.pendaftaran?.id } }" class="btn btn-sm btn-primary bg-primary"><i class="fas fa-search"></i> Detail</router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </datatable>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
.auto {
    width: auto;
    height: auto;
}
</style>
